import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/chat.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                        <StaticImage src={'../../assets/images/features/chat.png'} 
                                        alt="" 
                                        className="features"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id = "escape"><img className="icon" src={icon} /> Chat</h2>
                            <p id="escape2">In the age of remote working, an effective chat tool can quickly become the backbone of your business. Tafoma’s chat room is a comprehensive, networking tool for real-time team collaboration with file-sharing capabilities and it enables its users to exchange text messages, images, videos, and hyperlinks.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne