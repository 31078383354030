import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/spreadsheets.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 mobile-only">
                        <div className="text-center">
                            <StaticImage src={'../../assets/images/features/spreadsheet.png'} 
                                        alt=""
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id="escape"><img className="icon" src={icon} /> Spreadsheets</h2>
                            <p id="escape2">Tafoma Spreadsheets allows users to unlock the potential of data, by using formulas across a grid of cells. Data is inserted into individual cells in rows or columns, allowing it to be sorted and filtered, and then displayed in a visual presentation (charts, graphs, clustered columns).  Tafoma spreadsheets recommends charts most suitable for the type of data being presented on X and Y axis.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 desktop-only">
                        <div className="text-center features">
                            {/* <img className="ptb-50" src={feature} alt="banner" /> */}
                            <StaticImage src={'../../assets/images/features/spreadsheet.png'} 
                                        alt="" 
                                        className="features ptb-50"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne