import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import feature from '../../assets/images/features/tasks.png'
import icon from '../../assets/images/features/icons/tasks.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                            {/* <img className="features" src={feature} alt="banner" /> */}
                            <StaticImage src={'../../assets/images/features/tasks.png'} 
                                        alt="" 
                                        className="features"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        loading="eager"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id = "escape"><img className="icon" src={icon} /> Tasks</h2>
                            <p id="escape2">The tasks module is the foundation of Tafoma’s purpose. Our user-friendly and responsive answer to streamlining project management. You can create tasks for yourself or assign tasks to anyone on your team. Incorporating both waterfall and agile methodologies, we are giving you maximum flexibility. Tasks can be comprised of: a description, comments, to-do lists, expenses, and time management sections. Further still, you can implement start and due dates, set a project’s priory level, leave reminders, and even show the percentage of completion. You can of course attach relevant files including pictures, documents, and PDFs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne