import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/gantt_chart.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25 bg-e7edf9">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 mobile-only">
                        <div className="text-center">
                            <StaticImage src={'../../assets/images/features/gantt_chart.png'} 
                                        alt=""
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id="escape"><img className="icon" src={icon} />Gantt Chart</h2>
                            <p id="escape2">Stay on top of your team’s management by using our elegantly designed Gantt chart tool to create a comprehensive and easy-to-read overview of a project’s time-line.  By providing a visual outline of milestones and other key dates, these charts offer a more understandable and memorable method of maintaining timescale-based tasks and projects whether tracked on a daily, weekly, monthly or yearly basis.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 desktop-only">
                        <div className="text-center features">
                        <StaticImage src={'../../assets/images/features/gantt_chart.png'} 
                                        alt="" 
                                        className="features ptb-50"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne