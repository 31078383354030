import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/video_meeting.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                            <StaticImage src={'../../assets/images/features/video_meeting.png'} 
                                        alt="" 
                                        className="features"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id = "escape"><img className="icon" src={icon} /> Video Meetings</h2>
                            <p id="escape2">Use our in-app video conferencing to efficiently collaborate remotely with everyone on your team, both on desktop and mobile devices. You can even invite someone without a Tafoma account to a meeting. Tafoma’s video conferencing lets you expand the ambition of your business without compromising on productivity and engagement. Tafoma’s platform allows you to have unlimited screen-sharing time up to 50 users.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne