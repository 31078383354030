import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/social_wall.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                        <StaticImage src={'../../assets/images/features/social_wall.png'} 
                                        alt="" 
                                        className="features"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id = "escape"><img className="icon" src={icon} /> Social Wall</h2>
                            <p id="escape2">Our social wall has been built from the ground up, enriching company culture by providing your team with a more connected, informed, and harmonious experience. Tafoma’s social wall is a hit among users. It adds a uniqueness to their engagements with peers, through networking, social events and happy hours. You will love how simple yet satisfying it is to create a post for all your team to see.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne